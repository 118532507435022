<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>供应链管理</template>
      <template v-slot:secondMenu>供应商管理</template>
    </breadcrumb-nav>
    <el-card>
    <el-button
      type="primary"
      icon="el-icon-plus"
      style="margin: 10px 0px"
      @click="showDialog"
    >添加
    </el-button>
    </el-card>
    <el-card style="margin-top: 20px">
    <el-table style="width: 100%" border :data="list">
      <el-table-column type="index" label="序号" width="80px" align="center">
      </el-table-column>
      <el-table-column prop="imageUrl" label="图标" width="100px">
        <template slot-scope="{ row }">
          <img :src="row.imageUrl" alt="" style="width: 50px; height: 50px" />
        </template>
      </el-table-column>
      <el-table-column prop="name" label="名称" width="120px">
      </el-table-column>
      <el-table-column prop="code" label="编码" width="120px">
      </el-table-column>
      <el-table-column prop="env" label="环境">
        <template slot-scope="scope">{{ scope.row.env | envTypeLabel }}</template>
      </el-table-column>
      <el-table-column prop="host" label="访问地址">
      </el-table-column>
      <el-table-column prop="appId" label="应用id">
      </el-table-column>
      <el-table-column prop="appSecret" label="应用密钥">
      </el-table-column>
      <el-table-column prop="desc" label="描述">
      </el-table-column>
      <el-table-column prop="imgHost" label="图片服务器地址">
      </el-table-column>
      <el-table-column prop="prop" label="操作" width="200px;">
        <template slot-scope="{row}">
          <el-row>
            <el-button
              type="warning"
              icon="el-icon-edit"
              size="mini"
              @click="updateSupplier(row)"
            >修改</el-button
            >
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="listQuery.page"
      :page-sizes="[5,10,20]"
      :page-size="listQuery.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    </el-card>
    <el-dialog
      :title="form.id ? '修改供应商信息' : '添加供应商信息'"
      :visible.sync="dialogFormVisible"
    >
      <el-form style="width: 80%" :model="form" :rules="rules" ref="form">
        <el-form-item label="名称" label-width="100px" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="编码" label-width="100px" prop="name">
          <el-input v-model="form.code"></el-input>
        </el-form-item>
        <el-form-item label="环境" label-width="100px" prop="env">
          <el-select v-model="form.env" style="width:100%" placeholder="请选择环境">
            <el-option
              v-for="item in envTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="访问地址" label-width="100px" prop="title">
          <el-input v-model="form.host"></el-input>
        </el-form-item>
        <el-form-item label="应用id" label-width="100px" prop="title">
          <el-input v-model="form.appId"></el-input>
        </el-form-item>
        <el-form-item label="应用密钥" label-width="100px" prop="title">
          <el-input v-model="form.appSecret"></el-input>
        </el-form-item>
        <el-form-item label="描述" label-width="100px" prop="desc">
          <el-input v-model="form.desc"></el-input>
        </el-form-item>
        <el-form-item label="图片服务器地址" label-width="100px" prop="desc">
          <el-input v-model="form.imgHost"></el-input>
        </el-form-item>
        <el-form-item label="图标" label-width="100px" prop="icon">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="handleUploadSuccess"
            :headers="imgUploadHeaders"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addOrUpdateSupplier"
        >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BreadcrumbNav from '../common/BreadcrumbNav'
import { getSupplierListRequest, updateSupplierRequest, addSupplierRequest } from '../../network/supply'
export default {
  name: 'Supplier',
  components: {
    BreadcrumbNav
  },
  filters: {
    envTypeLabel (value) {
      switch (value) {
        case 'test':
          return '测试环境'
        case 'product':
          return '正式环境'
      }
    }
  },
  data () {
    return {
      // 总共数据条数
      total: 0,
      // 列表展示的数据
      list: [],
      // 对话框显示与隐藏控制的属性
      dialogFormVisible: false,
      uploadUrl: window.g.MEDIA_URL + '/upload',
      imgUploadHeaders: { // 上传图片控件的header
        Authorization: sessionStorage.getItem('token')
      },
      imageUrl: '',
      // 收集签到规则信息
      form: {
        icon: '',
        name: '',
        code: '',
        env: '',
        desc: '',
        host: '',
        appId: '',
        appSecret: '',
        imgHost: ''
      },
      // 表单验证规则
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        code: [{ required: true, message: '请输入编码', trigger: 'blur' }],
        desc: [{ required: true, message: '请输入描述', trigger: 'blur' }],
        env: [{ required: true, message: '请输入环境', trigger: 'blur' }],
        host: [{ required: true, message: '请输入访问地址', trigger: 'blur' }],
        appId: [{ required: true, message: '请输入应用id', trigger: 'blur' }],
        appSecret: [{ required: true, message: '请输入应用密钥', trigger: 'blur' }],
        icon: [{ required: true, message: '请选择图标' }]
      },
      // 任务类型
      envTypes: [{
        label: '测试环境',
        value: 'test'
      }, {
        label: '正式环境',
        value: 'product'
      }],
      listQuery: {
        page: 1,
        size: 10
      }
    }
  },
  // 组件挂载完毕发请求
  mounted () {
    // 获取列表数据方法
    this.getSupplierList()
  },
  methods: {
    getSupplierList () {
      getSupplierListRequest(this.listQuery).then(res => {
        const result = res.data
        console.log('share conf list:', result.data.list)
        if (res.status !== 200) {
          return this.alertMessage('获取分享配置列表失败', 'error')
        }
        this.list = result.data.list
        for (const item of this.list) {
          item.imageUrl = window.g.MEDIA_DOWNLOAD_URL + item.icon
        }
        this.total = result.data.total
      })
    },
    updateSupplier (row) {
      console.log('update row:', row)
      this.dialogFormVisible = true
      this.form = { ...row }
      this.imageUrl = window.g.MEDIA_DOWNLOAD_URL + this.form.icon
    },
    showDialog () {
      this.dialogFormVisible = true
      this.form = { icon: '', name: '', code: '', desc: '', host: '', env: '', appId: '', appSecret: '' }
      this.imageUrl = ''
    },
    updateSupplier2Srv () {
      updateSupplierRequest(this.form).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改供应商信息失败', 'error')
        }
        this.alertMessage('修改供应商信息成功', 'success')
        this.getSupplierList()
      })
    },
    addSupplier2Srv () {
      addSupplierRequest(this.form).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('添加供应商信息失败', 'error')
        }
        this.alertMessage('添加供应商信息成功', 'success')
        this.getSupplierList()
      })
    },
    addOrUpdateSupplier () {
      this.$refs.form.validate(valid => {
        if (!valid) return
        this.dialogFormVisible = false
        console.log('supplier form:', this.form)
        if (this.form.id) {
          this.updateSupplier2Srv()
        } else {
          this.addSupplier2Srv()
        }
      })
    },
    handleUploadSuccess (res, file) {
      console.log('handleUploadSuccess res:', res)
      this.form.icon = res.data.list[0].mediaId
      this.imageUrl = window.g.MEDIA_DOWNLOAD_URL + this.form.icon
    },
    handleImgRemove (file) {
      this.form.icon = ''
      this.imageUrl = ''
    },
    handleSizeChange (newSize) {
      this.listQuery.size = newSize
      this.getSupplierList()
    },
    handleCurrentChange (newPage) {
      this.listQuery.page = newPage
      this.getSupplierList()
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
